@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap");

body {
  /* background-color: #1b1049; */
  background-color: #124f5b;
  color: #fff;
  height: 100%;
  position: relative;
  font-family: "IBM Plex Sans", sans-serif;
}

a {
  text-decoration: none;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-track {
  width: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #124f5b;
}

.fs-10 {
  font-size: 10px;
}

.fs-12 {
  font-size: 12px;
}

.fs-13 {
  font-size: 13px;
}

.fs-15 {
  font-size: 15px;
}

.fs-30 {
  font-size: 30px;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-800 {
  font-weight: 800;
}

.grey-text {
  color: #828080;
}

.blue-color {
  color: #03A9F4;
}

.green-text {
  color: rgb(0, 255, 136);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.red-text {
  color: red;
}

.violet-color {
  color: #cddc39;
}

.orange-color {
  color: #cddc39;
}

.top-navbar-new {
  border-bottom: 1px solid #adadad2f !important;
}

.border-top {
  border-top: 1px solid #adadad2f !important;
}

.border-bottom {
  border-bottom: 1px solid #adadad2f !important;
}

.border {
  border: 1px solid #adadad2f !important;
}

.new-trans-page-design thead tr {
  border-bottom: 1px solid #ffffff1e;
}

.new-trans-page-design tbody tr {
  border-bottom: 1px solid #ffffff1e;
}

.new-trans-page-design tbody tr:last-child {
  border-bottom: 0px solid #ffffff1e;
}

/* / ============ DESKTOP-VIEW ============ / */

@media all and (min-width: 992px) {
  .new-trans-assest-image {
    width: 18px;
    height: auto;
    margin-right: 2px;
    border-radius: 50px;
  }

  .new-trans-page-design th {
    width: 20%;
    opacity: 0.8;
    font-size: 14px;
    letter-spacing: 0.5px;
  }

  .new-trans-page-design td {
    font-size: 13px;
  }

  .new-trans-page-design .table>:not(caption)>*>* {
    border-bottom-width: 0px;
  }

  .new-trans-page-design .card {
    background: linear-gradient(180deg, #2d2f7626, #de705f26);
  }

  .form-control-new-1 {
    font-size: 13px;
    background: linear-gradient(180deg, #2d2f7626, #de705f10);
    border: 1px solid #adadad2f !important;
    color: #FFF;
  }

  .form-control-new-1:focus {
    color: #FFF;
    outline: 0;
    box-shadow: none !important;
    border: 1px solid #adadad2f !important;
    background: linear-gradient(180deg, #2d2f7626, #de705f10);
  }

  .logo-img-css {
    height: 80px;
    width: 80px;
  }

  .sidenav-column-section .form-select:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none !important;
  }

  .sidenav-column-section .form-select {
    background: linear-gradient(180deg, #2d2f7626, #de705f10);
    font-size: 13px;
    border: 1px solid #adadad1c !important;
    color: #FFF;
    background-image: url("./images/arrow-down.png");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
  }

  .sidenav-column-section option {
    color: #000;
    font-size: 15px;
  }

  .box-icon-styling-css {
    align-items: center;
    /* background: linear-gradient(89.92deg, #9d44ee 0.05%, #5973ee 98.43%); */
    /* background: linear-gradient(89.92deg, #0dcaf0 0.05%, #5a7cf6 98.43%); */
    /* border-radius: 50px; */
    display: flex;
    /* font-size: 24px; */
    /* height: 45px; */
    /* justify-content: center; */
    width: 45px;
  }

  /* .box-icon-styling-css:hover {
    background: linear-gradient(89.92deg, #5a7cf6 0.05%, #0dcaf0 98.43%);
  } */

  .connect-button-styling-css {
    color: #fff;
    font-weight: 900;
    /* text-transform: uppercase; */
    /* background: linear-gradient(89.66deg, #34343c 7.4%, #fc58ff00 110.79%); */
    background: linear-gradient(89.66deg, #0dcaf0 7.4%, #5a7cf6 110.79%);
    border: 0;
    border-radius: 16px;
    font-size: 14px;
    padding: 10px 20px !important;
  }

  .connect-button-styling-css:hover {
    background: linear-gradient(89.66deg, #5a7cf6 7.4%, #0dcaf0 110.79%);
    color: #ffffff;
  }

  .refresh-button-styling-css {
    background: linear-gradient(90deg, #0dcaf0 2.32%, #5a7cf6 97.93%);
    color: #ffffff;
    font-size: 14px;
    box-shadow: none;
    outline: none;
    border: none;
  }

  .refresh-button-styling-css:hover {
    background: linear-gradient(90deg, #5a7cf6 2.32%, #0dcaf0 97.93%);
    color: #ffffff;
  }

  .refresh-button-styling-css:active {
    border: none !important;
  }

  .small-button-styling-css {
    background: linear-gradient(to right, #a344f1, #4870a9);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: #ffffff;
    font-size: 14px;
    box-shadow: none;
    outline: none;
    border: none;
  }

  .small-button-styling-css:hover {
    color: #ffffff;
    background: linear-gradient(to right, #4870a9, #a344f1);
  }

  .sidenav-column-section {
    /* border-right: 1px solid #adadad2f; */
    padding: 30px 20px;
  }

  .sidenav-column-section-2 {
    border-left: 1px solid #adadad2f;
    padding: 30px 20px;
  }

  .sidenav-icons-styling {
    font-size: 34px;
    background-color: #ececec;
    border-radius: 50%;
    padding: 0.2em;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
      rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
      rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    margin-top: 0.6em;
  }

  .sidenav-icons-styling-2 {
    font-size: 34px;
    background-color: #ececec;
    border-radius: 50%;
    padding: 0.2em;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
      rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
      rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .sidenav-dark-white-img-styling {
    width: 35px;
    height: 35px;
    background-color: #ececec;
    border-radius: 50%;
    padding: 0.2em;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
      rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
      rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }



  .small-button-styling-css-2 {
    background-color: red;
    color: #ffffff;
    font-size: 10px;
    width: 80px;
  }

  .small-button-styling-css-2:hover {
    background-color: #bf0000;
    color: #ffffff;
    font-size: 10px;
    width: 80px;
    box-shadow: none;
    outline: none;
  }



  .number-input-bg-styling {
    /* background-color: #ffffff; */
    padding: 0.5em;
    margin: 0.3em;
  }

  .side-nav-bg-styling {
    position: -webkit-sticky;
    /* For Safari */
    position: sticky;
    top: 0;
    /* Stick to the top */
    /* left: 0; */
    /* Stick to the left */
    height: 100vh;
    /* Take the full viewport height */
    /* width: 50px; */
    /* Just an example width, adjust accordingly */
    z-index: 10;
    /* Make sure the container floats on top of other content */
  }

  #hideshowprofit {
    display: none;
  }

  #hideshowloss {
    display: none;
  }

  .btn:focus {
    box-shadow: none !important;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: linear-gradient(180deg, #00bcd4, #de705f26);
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 15px;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.uni-sushi-price {
  min-height: 40px;
}

/* / ============ MOBILE-VIEW ============ / */

@media (max-width: 991px) {
  .table-trans-height .table {
    width: 1000px;
  }

  .table-trans-height {
    overflow-x: scroll;
  }

  .new-trans-assest-image {
    width: 18px;
    height: auto;
    margin-right: 2px;
    border-radius: 50px;
  }

  .new-trans-page-design th {
    width: 20%;
    opacity: 0.8;
    font-size: 14px;
    letter-spacing: 0.5px;
  }

  .new-trans-page-design td {
    font-size: 13px;
  }

  .new-trans-page-design .table>:not(caption)>*>* {
    border-bottom-width: 0px;
  }

  .new-trans-page-design .card {
    background: linear-gradient(180deg, #2d2f7626, #de705f26);
  }

  .form-control-new-1 {
    font-size: 13px;
    background: linear-gradient(180deg, #2d2f7626, #de705f10);
    border: 1px solid #adadad2f !important;
    color: #FFF;
  }

  .form-control-new-1:focus {
    color: #FFF;
    outline: 0;
    box-shadow: none !important;
    border: 1px solid #adadad2f !important;
    background: linear-gradient(180deg, #2d2f7626, #de705f10);
  }

  .logo-img-css {
    height: 80px;
    width: 80px;
  }

  .sidenav-column-section .form-select:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none !important;
  }

  .sidenav-column-section .form-select {
    background: linear-gradient(180deg, #2d2f7626, #de705f10);
    font-size: 13px;
    border: 1px solid #adadad1c !important;
    color: #FFF;
    background-image: url("./images/arrow-down.png");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
  }

  .sidenav-column-section option {
    color: #000;
    font-size: 15px;
  }

  .box-icon-styling-css {
    align-items: center;
    background: linear-gradient(89.92deg, #9d44ee 0.05%, #5973ee 98.43%);
    border-radius: 50px;
    display: flex;
    font-size: 24px;
    height: 45px;
    justify-content: center;
    width: 45px;
  }

  .box-icon-styling-css:hover {
    background: linear-gradient(89.92deg, #5973ee 0.05%, #9d44ee 98.43%);
  }

  .connect-button-styling-css {
    color: #fff;
    font-weight: 900;
    text-transform: uppercase;
    background: linear-gradient(89.66deg, #6a68ef 7.4%, #fc58ff00 110.79%);
    border: 0;
    border-radius: 16px;
    font-size: 14px;
    padding: 10px 20px !important;
  }

  .connect-button-styling-css:hover {
    background: linear-gradient(89.66deg, #6a68ef 7.4%, #fc58ff00 110.79%);
    color: #ffffff;
  }

  .refresh-button-styling-css {
    background: linear-gradient(90deg, #a344f159 2.32%, #5a7cf665 97.93%);
    color: #ffffff;
    font-size: 14px;
    box-shadow: none;
    outline: none;
    border: 1px solid #a344f117;
  }

  .refresh-button-styling-css:hover {
    background: linear-gradient(90deg, #5a7cf665 2.32%, #a344f159 97.93%);
    color: #ffffff;
  }

  .refresh-button-styling-css:active {
    border: none !important;
  }

  .small-button-styling-css {
    background: linear-gradient(to right, #1d976cbe, #97ffdb50);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    color: #ffffff;
    font-size: 14px;
    box-shadow: none;
    outline: none;
    border: 1px solid #a344f117;
  }

  .small-button-styling-css:hover {
    color: #ffffff;
  }

  .sidenav-column-section {
    border-right: 1px solid #adadad2f;
    padding: 30px 20px;
  }

  .sidenav-icons-styling {
    font-size: 34px;
    background-color: #ececec;
    border-radius: 50%;
    padding: 0.2em;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
      rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
      rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    margin-top: 0.6em;
  }

  .sidenav-icons-styling-2 {
    font-size: 34px;
    background-color: #ececec;
    border-radius: 50%;
    padding: 0.2em;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
      rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
      rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  .sidenav-dark-white-img-styling {
    width: 35px;
    height: 35px;
    background-color: #ececec;
    border-radius: 50%;
    padding: 0.2em;
    outline: none;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
      rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
      rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }



  .small-button-styling-css-2 {
    background-color: red;
    color: #ffffff;
    font-size: 10px;
    width: 80px;
  }

  .small-button-styling-css-2:hover {
    background-color: #bf0000;
    color: #ffffff;
    font-size: 10px;
    width: 80px;
    box-shadow: none;
    outline: none;
  }



  .number-input-bg-styling {
    /* background-color: #ffffff; */
    padding: 0.5em;
    margin: 0.3em;
  }

  .side-nav-bg-styling {
    position: -webkit-sticky;
    /* For Safari */
    position: sticky;
    top: 0;
    /* Stick to the top */
    /* left: 0; */
    /* Stick to the left */
    height: 100vh;
    /* Take the full viewport height */
    /* width: 50px; */
    /* Just an example width, adjust accordingly */
    z-index: 10;
    /* Make sure the container floats on top of other content */
  }

  #hideshowprofit {
    display: none;
  }

  #hideshowloss {
    display: none;
  }

  .btn:focus {
    box-shadow: none !important;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: linear-gradient(180deg, #2d2f7626, #de705f26);
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

/*==============================SWIPER-DEMOS-START=========================*/



@media (max-width: 760px) {
  .swiper-button-next {
    right: 20px;
    transform: rotate(90deg);
  }

  .swiper-button-prev {
    left: 20px;
    transform: rotate(90deg);
  }
}

.custom-icons {
  border: 1px solid linear-gradient(90deg, #a344f159 2.32%, #5a7cf665 97.93%);
  padding: 5px 3px;
  height: 33px;
  border-radius: 69px;
  font-size: 27px;
  width: 33px;
  /* background: linear-gradient(90deg, #a344f159 2.32%, #5a7cf665 97.93%); */
  background: linear-gradient(90deg, #0dcaf0 2.32%, #5a7cf6 97.93%);

}

.custom-icons:hover {
  background: linear-gradient(to right, #5a7cf6, #0dcaf0);
}